@tailwind base;
@tailwind components;
@layer base {
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
  body {
    font-family: 'Open Sans', sans-serif;
    @apply text-gray-900;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  h4 {
    @apply text-base;
  }

  h5 {
    @apply text-sm;
  }

  h6 {
    @apply text-xs;
  }

  p {
    @apply text-gray-900 whitespace-pre-wrap;
  }
  .flex-center {
    @apply flex items-center justify-center;
  }
}
@tailwind utilities;
